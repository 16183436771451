import React from 'react';
import PropTypes from 'prop-types';

const ResearchSection = ({ children, title, image }) => (
  <div className='lg:w-1/2'>
    <img src={image} alt={title} className='flex-none mb-4 h-16' />

    <h2 className='font-sans font-semibold text-blue text-2xl leading-very-tight mb-4'>
      {title}
    </h2>

    <div className='font-sans font-normal text-grey leading-snug'>
      {children}
    </div>
  </div>
);

ResearchSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
};

ResearchSection.defaultProps = {
  image: '',
  title: '',
};

export default ResearchSection;
