import React from 'react';
import Section from './genetic-findings/section';
import fannyCoron from '../../images/fanny-coron.png';
import carrierStatus from '../../images/carrier-status.png';
import medicallyActionable from '../../images/medically-actionable.png';
import participantTraits from '../../images/participant-traits.png';

const GeneticFindings = () => (
  <>
    <div className='container mx-auto px-8 my-16'>
      <div className='flex justify-between flex-col lg:flex-row mb-16 mt-8'>
        <div className='lg:w-1/2'>
          <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl leading-very-tight mb-8'>
            Your Genetic Findings
          </h2>

          <p className='font-soft font-semibold text-lg text-grey leading-snug mb-4'>
            Participants of the NL Genome Project can choose whether or not to receive findings about their genetic makeup. Sequence Bio has its own genetic counselling team to help you and your doctor best understand these findings.
          </p>

          <p className='font-sans font-normal text-grey leading-snug mb-4'>
            There are three different categories of findings that Sequence Bio plans to develop and return. Some will be returned to your doctor to discuss with you, while others will be returned directly to you.
          </p>
        </div>

        <div className='lg:w-2/5'>
          <img src={fannyCoron} alt='Genetic Counselling Support' className='-mb-8 ml-6 h-24' />

          <div className='bg-grey-100 p-6 pt-10 lg:p-8 lg:pt-12 rounded'>
            <h5 className='font-sans font-semibold text-blue mb-2'>
              Genetic Counselling Support
            </h5>

            <p className='font-sans text-sm text-black'>
              Sequence Bio has its own genetic counselling team that will develop and validate the content for several types of findings reports and support the return of the results to your doctor. Sequence Bio’s lead genetic counsellor will be responsible for coordinating a team that will provide genetic counselling services at no cost to participants and their doctors.
            </p>
          </div>
        </div>
      </div>
    </div>


    <div className='bg-white lg:bg-gradient-b-grey-100-to-transparent lg:pt-16'>
      <div className='container mx-auto px-8'>
        <div className='flex justify-between flex-col lg:flex-row mb-8 mt-8'>
          <div className='lg:w-1/3 lg:pr-8'>
            <h2 className='font-sans font-bold text-blue-500 text-2xl lg:text-3xl leading-very-tight mb-4'>
              Findings To Your Doctor
            </h2>

            <p className='font-sans font-normal text-grey leading-snug'>
            Your doctor will discuss these findings with you and decide on next steps. These findings are research-grade. They will not be placed in your medical record.
            </p>
          </div>

          <div className='lg:w-2/3'>
            <ul>
              <li className='mb-8 flex'>
                <img src={medicallyActionable} alt='Medically Actionable Variants' className='mr-2 h-24' />

                <div>
                  <h4 className='font-sans font-semibold text-blue text-lg lg:text-xl leading-very-tight mb-2'>
                    Medically Actionable Variants
                  </h4>
                
                  <p className='font-sans font-normal text-grey leading-snug'>
                    Research-grade findings will be shared with your doctor if we detect a rare variant in one of the 59 medically actionable genes. These genes are directly linked to life-threatening diseases with possible treatment or screening options (such as increased risk for cancer or heart failure).
                  </p>
                </div>
              </li>

              <li className='flex'>
                <img src={carrierStatus} alt='Carrier Status' className='mr-2 h-24' />

                <div>
                  <h4 className='font-sans font-semibold text-blue text-lg lg:text-xl leading-very-tight mb-2'>
                    Carrier Status
                  </h4>

                  <p className='font-sans font-normal text-grey leading-snug'>
                    Information about some types of genetic changes for which you may be a carrier. This means you will not personally be affected, but there is a chance your child or future children could be affected by the condition or also be a carrier.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className='flex justify-between flex-col lg:flex-row mb-8 mt-16'>
          <div className='lg:w-1/3 lg:pr-8'>
            <h2 className='font-sans font-bold text-blue-500 text-2xl lg:text-3xl leading-very-tight mb-4'>
              Findings To Participants
            </h2>
          </div>


          <div className='lg:w-2/3'>
            <ul>
              <li className='mb-8 flex'>
                <img src={participantTraits} alt='Personal Traits' className='mr-2 h-24' />

                <div>
                  <h4 className='font-sans font-semibold text-blue text-lg lg:text-xl leading-very-tight mb-2'>
                    Personal Traits
                  </h4>

                  <p className='font-sans font-normal text-grey leading-snug mb-4'>
                    Information that is not medically relevant but might be of interest, like how you process caffeine, sensitivity to bitterness, and your eye or hair colour.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className='container mx-auto px-8 mt-24 mb-16'>
      <h2 className='font-sans font-semibold text-grey text-lg lg:text-xl leading-very-tight mb-8'>
        More Information About These Findings
      </h2>

      <div className='flex justify-between'>
        <p className='lg:w-1/3 font-sans font-normal text-sm text-grey-800 leading-snug mb-4 mr-12'>
          These findings are known as research-grade findings because we will be studying the DNA from your saliva sample in a research laboratory, not a clinical laboratory. Research-grade findings can not be used to make a diagnosis or to determine a treatment. That means your doctor may need to order further tests from a clinical laboratory to confirm your findings.
        </p>

        <p className='lg:w-1/3 font-sans font-normal text-sm text-grey-800 leading-snug mb-4 mr-12'>
          While Sequence Bio aims to eventually return all of the above-noted results to participants, the list of specific diseases, carrier findings, risks, or traits may change as our capabilities grow and scientific knowledge of the human genome improves. Actionable findings may not be found in all participants, and some findings could be deemed actionable several years after participation. Initial findings will take up to 18 months to return. These findings may not reveal anything of importance, or may reveal something potentially distressing.
        </p>

        <p className='lg:w-1/3 font-sans font-normal text-sm text-grey-800 leading-snug'>
          Our findings are only based on what we discover in your genes. Our analysis methods do not look at all possible changes in every single gene. Our findings do not represent a complete survey of your genes. If we do not find anything, it does not mean that there are not any changes. It could mean we did not detect any changes. You may still be at risk because you may have genetic changes that were not picked up using our analysis method. If we do not detect changes, we will not send a report to your doctor.
        </p>
      </div>
    </div>
  </>
);

export default GeneticFindings;
