import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/your-results/hero';
import GeneticFindings from '../components/your-results/genetic-findings';

const YourResults = () => (
  <Layout>
    <SEO
      title='Your Results'
      description=''
    />
    <Hero />
    <GeneticFindings />
  </Layout>
);

export default YourResults;
