import React from 'react';

const Hero = () => (
  <div className='your-results-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Your Results
        </h1>

        <p className='font-sans font-normal text-white mb-4'>
          Sequence Bio is committed to Newfoundlanders and Labradorians like you - our friends, family, and communities. That’s why we’ll share NL Genome Project findings with participants to potentially provide insight into their genetic makeup and inform better healthcare choices.
        </p>
      </div>
    </div>
  </div>
);

export default Hero;
